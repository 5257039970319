<template>
  <section class="container" @click="$emit('close-dropdown', $event.target.getAttribute('data-input') === null, $event)">
    <form-loading v-show="loading" :title="loadingTitle"/>
    <slot name="header" />
    <slot name="alert" />
    <slot />
    <!--<slot name="details" /> -->
    <footer>
      <button
      :style="{ backgroundColor: colorButton }"
      class="cancel" >
        <span @click="$emit('back-step')">
          <slot name="back">Voltar</slot>
        </span>
      </button>
      <button
      :style="{ backgroundColor: colorButton }"
      class="next"
      :disabled="disableSubmitButton"
      @click.prevent="submitButton"><slot name="next">Próximo</slot></button>
    </footer>
    <br />
  </section>
</template>

<script>
export default {
  name: 'base-form',
  props: {
    lock: {
      type: Boolean,
      default: false
    },
    loadingTitle: String,
    forwardBack: Number,
    endPoint: String,
    loading: {
      type: Boolean,
      default: false
    },
    disableSubmitButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'form-loading': require('@/components/Form/loading').default
  },
  computed: {
    colorButton () {
      return this.$route.meta.theme.colorButton ? this.$route.meta.theme.colorButton : ''
    }
  },
  mounted () {
    if (this.loading) {
      document.body.style.overflow = 'hidden'
    }
  },
  updated () {
    if (!this.loading) {
      document.body.style.overflow = 'visible'
    }
  },
  methods: {
    submitButton () {
      if (this.disableSubmitButton) {
        return
      }
      this.$emit('submeter')
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
  }
  footer {
    display: flex;
    justify-content: space-between;
    width: 86%;
    margin: 0 10%;
    margin-top: 20px;
    @media(max-width:560px){
      width: 100%!important;
      margin: 20px 0 0 0!important;
      justify-content: center;
      position: relative;
      button{
        height: 70px!important;
        flex: 1;
        font-size: 22px;
        font-weight: 100;
      }
    }
    button {
      border-style: none;
      background-color: #503660;
      color: rgba(255, 255, 255, 0.774);
      min-width: 160px;
      height: 60px;
      font-size: 1.3em;
      cursor: pointer;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .next {
      color: rgba(255, 255, 255, 1);
      position: relative;
    }
    .next:disabled {
      color: rgba(255, 255, 255, 0.425);
      cursor: not-allowed;
    }

    .next::before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      padding: 0;
      background-color: #FDB00B;
      position: absolute;
      left: -2px;
      top: 0;
      @media(max-width:560px){
        width: 5px!important;
      }
    }
  }
</style>
