import { url } from '@/api/base-url'

export function getFuncao () {
  return url.get('Funcoes')
}
export function getAreas () {
  return url.get('Areas')
}
export function getSector () {
  return url.get('parceiro/ListasFormulario')
}
export async function createEmpresa (empresa) {
  const response = await url.post('parceiros', empresa)
  return response
}
