<template>
  <form-base
  @submeter="submeter"
  :loading="loading"
  :loadingTitle="loadingTitle"
  class="theme form"
  ref="form"
  @close-dropdown="closeInputModal"
  :theme="theme">
  <confirm-modal
  v-if="greeting"
  @confirm="goToHome"
  ModalTitle="Bem-vindo ao RISE Work. Consulte o seu email para confirmar o seu registo."
  ><template #textButton>Fechar</template></confirm-modal>
    <template #header>
      <header role="header">
        <h2>Registo</h2>
      </header>
    </template>
    <div class="container-registo">
      <div class="input-field">
        <input-form
          color="secondary"
          :error="errors.nome[Object.keys(errors.nome)[0]] ? Object.keys(errors.nome)[0] : ''"
          title="Nome"
          placeholder=""
          v-model.trim="$v.nome.$model"
          class="form-input"
          :theme="theme"
          @focus="resetWidget"
        />
      </div>
      <div class="input-field">
        <input-form
          color="secondary"
          :error="errors.email[Object.keys(errors.email)[0]] ? Object.keys(errors.email)[0] : ''"
          title="E-mail"
          placeholder=""
          v-model.trim="$v.email.$model"
          class="form-input"
          :theme="theme"
          @focus="resetWidget"
        />
      </div>
      <div class="input-row">
        <div class="input-field" data-input>
          <input-datalist
            color="secondary"
            align="top"
            title="Indicativo"
            :error="errors.indicativo[Object.keys(errors.indicativo)[0]] ? Object.keys(errors.indicativo)[0] : ''"
            placeholder=""
            field="w_isIndicativo"
            :themeStyle="theme"
            :dataSource="indicativos"
            :value="indicativo.nome"
            :isShow="w_isIndicativo"
            @setData="(value) => setData('indicativo', value)"
            v-on:w_isIndicativo="toggleWidget"
            @focus="resetWidget"
          />
        </div>
        <div class="input-field" data-input>
          <input-phone-mask
            title="Telefone"
            :error="errors.phoneNumber[Object.keys(errors.phoneNumber)[0]] ? Object.keys(errors.phoneNumber)[0] : ''"
            v-model="$v.phoneNumber.$model"
            @input="$v.phoneNumber.$model = $event"
            :phoneNumber="$v.phoneNumber.$model"
            maxlength="9"
            placeholder=""
            theme="empresa"
            class="form-input"
            :style="{marginTop: '5px'}"
            @focus="resetWidget"
            :rules="[{ minLength: 9 }]"
          />
        </div>
      </div>
      <div class="input-row">
        <div class="input-field">
          <input-form
            color="secondary"
            title="Palavra-passe"
            :error="errors.password[Object.keys(errors.password)[0]] ? Object.keys(errors.password)[0] : ''"
            alert="minimo 6 digitos"
            placeholder=""
            class="form-input"
            v-model="$v.password.$model"
            @focus="resetWidget"
            type="password"
          />
        </div>
        <div class=" input-field">
          <input-form
            color="secondary"
            title="Confirma Palavra-passe"
            :error="errors.repassword[Object.keys(errors.repassword)[0]] ? Object.keys(errors.repassword)[0] : ''"
            placeholder=""
            class="form-input"
            v-model="$v.repassword.$model"
            @focus="resetWidget"
            type="password"
          />
        </div>
      </div>
      <div class="input-field">
        <input-form
          color="secondary"
          :error="errors.empresa[Object.keys(errors.empresa)[0]] ? Object.keys(errors.empresa)[0] : ''"
          title="Empresa"
          placeholder=""
          v-model="$v.empresa.$model"
          class="form-input"
          :theme="theme"
          @focus="resetWidget"
        />
      </div>
      <div class="input-field">
        <input-form
          color="secondary"
          :error="errors.nif[Object.keys(errors.nif)[0]] ? Object.keys(errors.nif)[0] : ''"
          title="NIF"
          placeholder=""
          :maxlen="10"
          v-model="$v.nif.$model"
          class="form-input"
          :theme="theme"
          @focus="resetWidget"
        />
      </div>
       <div class="input-field">
          <input-datalist
            color="secondary"
            align="top"
            title="Sector de actividade"
            :error="errors.sector[Object.keys(errors.sector)[0]] ? Object.keys(errors.sector)[0] : ''"
            placeholder=""
            field="w_isArea"
            :themeStyle="theme"
            :dataSource="sectores"
            :value="sector.nome"
            :isShow="w_isArea"
            @setData="(value) => setData('sector', value)"
            v-on:w_isArea="toggleWidget"
            @focus="resetWidget"
          />
        </div>
        <div class="input-field">
          <input-form
            color="secondary"
            :error="errors.funcao[Object.keys(errors.funcao)[0]] ? Object.keys(errors.funcao)[0] : ''"
            title="Função"
            placeholder=""
            v-model="$v.funcao.$model"
            class="form-input"
            field="w_isFuncao"
            @focus="toggleWidget"
          />
        </div>
    </div>
    <template #back><span @click="$router.go(-1)">Cancelar</span></template>
    <template #next>submeter registo</template>
  </form-base>
</template>

<script>
import BaseForm from '@/components/Form/base-form'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import { createEmpresa, getSector } from '@/api/services/empresa'
export default {
  name: 'form-data-empresa',
  components: {
    'form-base': BaseForm,
    'input-form': require('@/components/Form/input-form').default,
    'input-phone-mask': require('@/components/Form/input-phone-mask').default,
    'input-datalist': require('@/components/Form/input-datalist').default,
    'confirm-modal': require('@/components shared/Modal/ConfirmModal2').default
  },
  props: {
    theme: {
      type: Object,
      default: () => ({
        color: undefined
      })
    }
  },
  computed: {
    ...mapGetters('register', [
      'indicativos'
    ])
  },
  data () {
    return {
      w_isArea: false,
      w_isFuncao: false,
      w_isSectores: false,
      w_isIndicativo: false,
      nome: '',
      email: '',
      sector: '',
      sectores: [],
      funcao: '',
      empresa: '',
      nif: '',
      password: '',
      greeting: false,
      repassword: '',
      indicativo: '',
      phoneNumber: '',
      loadingTitle: '',
      errors: {
        nome: {
          'Nome inválido': false
        },
        email: {
          'E-mail inválido': false
        },
        password: {
          'Palavra-passe inválida': false
        },
        repassword: {
          'Confirmação da Palavra-passe': false
        },
        empresa: {
          'Empresa inválida': false
        },
        phoneNumber: {
          'Número de telefone inválido': false
        },
        sector: {
          'Sector inválido': false
        },
        indicativo: {
          'Indicativo inválido': false
        },
        funcao: {
          'Função inválida': false
        },
        nif: {
          'NIF inválido': false
        }
      },
      loading: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    nome: {
      required,
      minLength: minLength(2)
    },
    empresa: {
      required
    },
    password: {
      required,
      minLength: minLength(6)
    },
    repassword: {
      required
    },
    phoneNumber: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9)
    },
    funcao: {
      required
    },
    nif: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    }
  },
  methods: {
    ...mapMutations('register', ['resetState']),
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    goToHome () {
      this.greeting = false
      this.$router.push({ path: '/' })
    },
    closeInputModal (isCloseDropdown) {
      if (isCloseDropdown) {
        this.resetWidget()
      }
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    resetWidget () {
      this.w_isArea = false
      this.w_isFuncao = false
      this.w_isSectores = false
      this.w_isIndicativo = false
    },
    submeter () {
      const { funcao, sector, indicativo, $v, errors } = this
      const { nome, email, password, repassword, empresa, phoneNumber, nif } = $v
      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))
      if (nome.$model === '' || nome.$error) {
        const property = Object.keys(errors.nome)[0]
        this.errors.nome[property] = true
        ok = false
      }
      if (email.$model === '' || email.$error) {
        const property = Object.keys(errors.email)[0]
        this.errors.email[property] = true
        ok = false
      }
      if (password.$model === '' || password.$error) {
        const property = Object.keys(errors.password)[0]
        this.errors.password[property] = true
        ok = false
      }
      if (password.$model !== '' && repassword.$model.localeCompare(password.$model) !== 0) {
        const property = Object.keys(errors.repassword)[0]
        this.errors.repassword[property] = true
        ok = false
      }
      if (empresa.$model === '' || empresa.$error) {
        const property = Object.keys(errors.empresa)[0]
        this.errors.empresa[property] = true
        ok = false
      }
      if (nif.$model === '' || nif.$error) {
        const property = Object.keys(errors.nif)[0]
        this.errors.nif[property] = true
        ok = false
      }
      if (phoneNumber.$model === '' || phoneNumber.$error) {
        const property = Object.keys(errors.phoneNumber)[0]
        this.errors.phoneNumber[property] = true
        ok = false
      }
      if (sector === '') {
        const property = Object.keys(errors.sector)[0]
        this.errors.sector[property] = true
        ok = false
      }
      if (indicativo === '') {
        const property = Object.keys(errors.indicativo)[0]
        this.errors.indicativo[property] = true
        ok = false
      }
      if (funcao === '') {
        const property = Object.keys(errors.funcao)[0]
        this.errors.funcao[property] = true
        ok = false
      }
      if (!ok) {
        return
      }

      this.loading = true
      this.loadingTitle = 'A submeter registo...'
      createEmpresa({
        nome: this.$v.nome.$model,
        email: this.$v.email.$model,
        telefone: '(' + parseInt(indicativo.nome) + ') ' + this.$v.phoneNumber.$model,
        password: this.$v.password.$model,
        funcao: this.$v.funcao.$model,
        idSector: sector.id,
        nif: this.$v.nif.$model,
        empresa: this.$v.empresa.$model
      })
        .then((response) => {
          this.loadingTitle = 'Registo submetido com sucesso.'
          setTimeout(() => {
            this.loading = false
            this.resetState()
            this.greeting = true
          }, 3000)
        })
        .catch(e => {
          if (e.response) {
            this.loadingTitle = e.response.data.message
          } else {
            this.loadingTitle = e.message
          }
          setTimeout(() => {
            this.loading = false
          }, 3000)
        })
    },
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    }
  },
  mounted () {
    getSector().then(res => res.data.object).then(data => {
      this.sectores = data.sectores
    })
  }
}
</script>
<style lang="scss" scoped>
.theme {
  color: #78C4C7;
  .form-input {
    ::placeholder {
      color: #78C4C7 !important;
    }
    color: #78C4C7 !important;
  }
}
.form {
  width: 100%;
}
.container-registo {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
}
.input-field {
  margin: 12px 0;
  p {
    text-align: left;
    margin: 0;
    margin-bottom: 2px;
  }
}
.input-row {
  display: flex;
  justify-content: space-between;
  & > .input-field {
    flex: 47%;
    max-width: 47%;
  }
}
header {
  width: 62%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
  @media(max-width:560px){
    margin-top: 135px;
    width: 100%!important;
    margin-left: unset!important;
    margin-bottom: 0!important;
    padding-left: 25px;
    h2{
      font-size: 18px!important;
    }
    p{
      margin-top: -5px;
      margin-left: 0!important;
      line-height: 1.2rem!important;
      font-size: 14px!important;
      font-weight: lighter!important;
    }
  }
  h2,
  p {
    font-weight: 100;
  }
  h2 {
    font-size: 24px;
    font-weight: normal;
  }
  p {
    margin-left: 2.6rem;
    line-height: 1.5rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px){
  .container-registo {
    width: 94%;
  }
  .input-row {
    flex-direction: column;
    & > .input-field {
      flex: 100%;
      max-width: 100%;
    }
  }
}
</style>
