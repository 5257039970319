<template>
  <main>
    <section class="forms">
        <form-data-empresa
        :theme="style"
        :form="dados" v-if="currentForm === 0" @nextSteps="(data, campo) => nextForm(1, campo,  data)"/>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'form-page',
  data () {
    return {
      currentForm: 0,
      dados: {
        pessoal: '',
        academic: '',
        idioma: ''
      },
      style: {
        color: '#26A0A5',
        backgroundStyle: {
          color: '#26A0A5'
        },
        textStyle: {
          color: '#fff'
        }
      }
    }
  },
  components: {
    'form-data-empresa': require('@/components/Form/form-data-empresa').default
  },
  methods: {
    ...mapActions('register', ['getPicklists']),
    setCurrentForm (current) {
      window.scrollBy({ top: 0, behavior: 'smooth' })
      this.currentForm = current
    },
    showCountry () {
    },
    nextForm (idForm, campo, dataForm) {
      this.currentForm = idForm
      this.dados[campo] = dataForm
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'NexaLight', sans-serif;
  .forms {
    margin-top: 30px;
  }
  @media(max-width:560px){
    height: auto!important;
    min-height: 100vh!important;
  }
  footer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    button {
      border-style: none;
      background-color: #503660;
      color: #ffffff;
      width: 100px;
      height: 40px;
      cursor: pointer;
    }
    .next {
      color: rgba(255, 255, 255, 0.425);
      position: relative;
    }
    .next::before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      padding: 0;
      background-color: #FDB00B;
      position: absolute;
      left: -2px;
      top: 0;
    }
  }
  .progress--bar {
      margin-top: -7rem;
    }
}
</style>
